<template>
  <div class="backg">
    <!-- <div class="imgDiv">
      <img class="topimg" src="../../assets/images/1234.png" alt="">
    </div> -->
    <!-- style="padding-top:15rem" -->
    <div style="padding-top:80%">
      <div class="ScanAdd" @click="wxUrlSign">
        <div class="addbox">
          扫码绑定卡/券
        </div>
      </div>
      <div class="wordAdd" @click="scanAdd">
        券密绑定
      </div>
      <div style="display: flex; flex-direction: column;width: 100%;align-items: center;margin-top: 1rem;">
        <div class="tagContextb">
          <span style="margin-left:6px;font-size: 17px;font-weight: 600;">温馨提示</span>
          <div class="tagContextc ">
            <p style="margin-top: 0.5rem;"><span class="ibox">·</span> 卡/券不记名，丢失无法找回，请立即绑定，卡/券绑定后不支持解绑! </p>
            <p style="margin-top: 0.5rem;"><span class="ibox">·</span> 卡/券过期后不支持绑定，请在有效期内使用! </p>
            <p style="margin-top: 0.5rem;"><span class="ibox">·</span> 兑换商品后可通过“我的订单”查询物流状态及订单信息!</p>
            <p style="margin-top: 0.5rem;"><span class="ibox">·</span> 客服热线:400-001-6621(9:00-21:00)</p>
          </div>
        </div>
      </div>
    </div>
    <BindGetKnow v-if="show" @closed="closeds" @sured="sured" :cardDate="cardDate"></BindGetKnow>
  </div>
</template>

<script>

import wx from "weixin-js-sdk";
import BindGetKnow from '../../components/card/BindGetKnow'
export default {
  name: "",
  data() {
    return {
      ticketType: '',
      show: false,
      cardDate: null,
      form: {
        qrCodeContent: '',
        userId: '',
        ticketType: '',
        flag: true,
        newTime: 0
      },
      timeinterval: 1500,
    };
  },
  components: { BindGetKnow },
  methods: {
    closeds() {
      this.show = false
    },
    scanAdd() {
      this.$router.push({
        path: "/BindCard",
        query: {
          ticketType: this.ticketType,
        },
      });
    },
    wxUrlSign() {
      let intTime = (new Date().getTime() - this.newTime);
      if (intTime < this.timeinterval) {
        this.$Toast('请不要连续点击')
        return;
      }
      this.newTime = new Date().getTime()
      //判断是否时安卓系统获取路径
      // let signLink = /(Android)/i.test(navigator.userAgent) ? location.href : window.entryUrl;
      let signLink = window.location.href.split('#');
      //刷新后路径变化使用当前路径
      // if (encodeURIComponent(signLink) == 'undefined') signLink = location.href
      this.$api
        .wxUrlSign(signLink)
        .then((res) => {
          if (res.code == 0) {
            wx.config(
              {
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数
                appId: res.data.appId,
                timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                signature: res.data.signature,// 必填，签名
                jsApiList: ['scanQRCode'],    // 必填，需要使用的JS接口列表
              });
            wx.error((res) => {
              console.log("获取失败");
            });
            wx.ready(() => {
              wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                success: (res) => {
                  this.onSubmit(res.resultStr)

                },
                cancel: () => {
                  //扫码失败
                },
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSubmit(val) {
      this.form.qrCodeContent = val
      this.form.ticketType = this.ticketType
      this.$api
        .checkBenefitCard(this.form)
        .then((res) => {
          if (res.code == 0) {
            this.cardDate = res.data
            this.show = true
          } else {
            this.$Toast(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // scan
    sured() {
      this.$api
        .boundBenefitCardByQR(this.form.qrCodeContent, this.ticketType).then((res) => {
          console.log(res)
          if (res.code == 0) {
            window.history.back()
            this.show = false
          }
          this.$Toast(res.msg)
        })
        .catch((err) => {
        });
    }
  },
  mounted() {
    this.ticketType = localStorage.getItem('entrCode')
    localStorage.getItem("userInfo")
      ? (this.form.userId = JSON.parse(localStorage.getItem("userInfo")).id)
      : ""
    if (localStorage.getItem('cardback')) {
      localStorage.removeItem('cardback')
      window.history.back()
    }
    this.newTime = 0
  },
  computed: {


  },
  watch: {},
};
</script>

<style lang='scss' scoped>
.ScanAdd {
  margin-left: 1.5rem;
  border-radius: 2rem;
  margin-right: 1.5rem;
  font-size: 1rem;
  // background-image: url("../../assets/images/button1.svg");
  background-image: linear-gradient(to right, #fefdf9, #fef2d8);
  background-size: cover;
  color: #74390b;
  overflow: hidden;
  text-align: center;
  padding: 0.538vh 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.addbox {
  width: 100%;
  height: 100%;
  line-height: 2.8rem;
  border-radius: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
}

.wordAdd {
  margin-top: 1rem;
  border-radius: 2rem;
  border: solid 1px #fff;
  padding: 1.65vh 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.1rem;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imgDiv {
  width: 100%;
  margin: 0 auto;
  height: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

.topimg {
  height: 24rem;
}

.backg {
  margin-left: -0.1rem;
  // background: linear-gradient(top, #c24a49, #af3b3c);
  // background: linear-gradient(top, #fc7f4c, #ef5f3d);
  // background-image: linear-gradient(top, #f2998c 0%, #b53d2c 100%);
  // background-image: linear-gradient(155deg, #e79562, #f44a4a);
  background: url("../../assets/images/bg12_30.png") no-repeat;
  background-size: cover;
  background-position: 0 -1.333vw;
  overflow: hidden;
  height: 100vh;
}

.tagtitle {
  // background-image: linear-gradient(155deg, #e32424, #fd680c);
  width: 8rem;
  color: #fff;
  padding: 0.8rem 1rem;
  text-align: center;
  border-radius: 3rem;
  border: 0.2rem solid #f1826c;
  z-index: 999;
}

.tagContextb {
  margin-top: 1rem;
  // background-image: linear-gradient(to bottom, #fdbea9, #e32424);
  // margin-top: -2rem;   ffecea
  color: #F9D3CD;
  border-radius: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.tagContextc {
  // background-color: #fff;
  // margin: 0.8rem;
  border-radius: 1rem;
  font-size: 10px;
  // padding: 2rem 0.8rem 1.5rem 0.8rem;
  letter-spacing: 0;
}

.ibox {
  font-weight: 700;
  margin-right: 0.1875rem;
}
</style>